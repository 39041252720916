import React from 'react';
import { Link, Box, Grid, Typography } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import { legalNoticeStyles } from './styles';

const TermsAndConditionsPage = () => {
  const { backgroundImagePrices } = useStaticQuery(
    graphql`
      query {
        backgroundImagePrices: file(relativePath: { eq: "heroBackground.jpeg" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );
  const pluginImage = getImage(backgroundImagePrices);

  const classes = legalNoticeStyles();
  return (
    <>
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <Box className={classes.boxImagesNoContent} />
          </Grid>
        </Grid>
      </BgImage>

      <Box className={classes.box}>
        <Typography paragraph variant="h1">
          Allgemeine Geschäftsbedingungen
        </Typography>

        <Typography variant="body1" paragraph>
          Die AGBs können{' '}
          <Link
            color="secondary"
            rel="norefferer"
            target="_blank"
            href="https://space.d-simple-storage.com/static/Allgemeine%20Gescha%CC%88ftsbedingungen.pdf"
          >
            hier
          </Link>{' '}
          heruntergeladen werden
        </Typography>

        <Typography variant="body1">
          Hirten Technologies
          <br />
          Augustastraße 1 <br />
          57555 Mudersbach <br />
          Deutschland
        </Typography>
        <Typography variant="body1" />
        <Typography variant="body1">
          <strong>Tel.:</strong> 0151 473 893 30
          <br />
          <strong>E-Mail:</strong> <a href="mailto:info@d-simple.com">info@d-simple.com</a>
        </Typography>
      </Box>
    </>
  );
};

export default TermsAndConditionsPage;
