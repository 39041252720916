import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const legalNoticeStyles = makeStyles(() => ({
  siteTitle: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: COLOR_WHITE,
    animation: 'textwelle 0.6s',
  },
  box: {
    minHeight: '550px',
    width: 'auto',
    margin: '0px 18% 0px 18%',
    paddingTop: '60px',
    // backgroundColor: '#344266',
  },
  boxoben: {
    minHeight: '70px',
    width: 'auto',
    backgroundColor: '#000038',
    // backgroundColor: '#344266',
  },
  boxImagesNoContent: {
    minHeight: '30px',
  },
  headImagesTextPosition: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '4em 1em 0em 1em',
  },
}));
