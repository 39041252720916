import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Layout } from '../components/layout';

import Seo from '../components/seo';
import TermsAndConditionsPage from '../components/AGB/termsAndConditions';

const agbPage = ({ pageContext }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('trans.agb')} lang={pageContext.language} />
      <TermsAndConditionsPage />
    </Layout>
  );
};
export default agbPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
